import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import SidebarUsuario from '../../partials/sidebarUsuario';
 

function VerPartidoIndividualUsuario() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { idPartido, nombrePartido }   = routeParams; // capturamos para paginación

  const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
  const [avatar, setAvatar] = useState("");
  const [videos, setVideos] = useState(null);
  const [partido, setPartido] = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  const [siguientePagina, setSiguientePagina] = useState(null);
  const [paginaAnterior, setPaginaAnterior] = useState(null); 
  const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
  const [paginaActual, setPaginaActual] = useState(() => {
    return parseInt(localStorage.getItem("paginaActual")) || 1;
  });
  
  

  useEffect(() => {

    async function obtenerDeportes(){
      await axios.post("/obtener-partido-global", {
        params: {nombrePartido: nombrePartido, idPartido: idPartido, desde: "cliente", pagina: paginaActual }
      })
      .then(async function(response){
        if(response.status===200){ 
          setCargando(false); // quitamos la espera
          setVideos(response.data.videosPaginacion); 
          setPartido(response.data.infoParido);
          setCantidadDePaginas(response.data.cantidadDePaginas);
          setSiguientePagina(response.data.siguientePagina);
          setPaginaAnterior(response.data.paginaAnterior);
          setPaginaActual(response.data.paginaActual);

        }
        else{
          setCargando(false); // quitamos la espera
        }
      });
    }
    obtenerDeportes();




    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
    if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
    else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
    // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      localStorage.setItem("paginaActual", 1);
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  
  async function atras(){
    // actualizar la p}agina actual
    let siguiente = paginaActual - 1; 
    localStorage.setItem("paginaActual", siguiente);
    window.location.reload();
  }

  async function adelante(){
    // actualizar la pagina actual
    let siguiente = paginaActual + 1; 
    localStorage.setItem("paginaActual", siguiente);
    window.location.reload();
    //console.log( "que imprime" + siguiente);
  }


  function handleVideoClick(datos){ 
    const {idVideo} = datos;
    
    let urlFinal = "/cliente/video/" + idVideo;
    // Navegar y forzar la recarga
    navigate(urlFinal, { replace: true });
    window.location.reload();  // Esto forzará la recarga completa de la página
  };

  





  
  return(
  <div className=''>
    <div className='d-flex flex-row '>

      {/* BARRA LATERAL */}
      <SidebarUsuario />

      {/* Columna 2 */}
      <div className="flex-grow-1 container-fluid">

      {/* Contenedor título  */}
      <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-white">
          <h1 className="tituloPaginas h2 text-white">Partido</h1>

          {/* Contenedor para búsqueda y filtro */}
          {/* <ContenedorFiltros urlVolver={urlVolver} usuario={username} /> */}


          {/* Avatar y saludo */}
          <div className="d-flex align-items-center text-white">
              <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
              <img className="imgHomeUsuario" src={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.avatar: null} alt="" />
          </div>
      </div>
        
        {/* Información del partido */}
        {(partido && partido.length > 0)
          ? <div id="" className="container bg-body-tertiary p-5 my-4 rounded-3" >
          <div className="d-flex justify-content-center ">
              <div className="row">
                  <div className="col-md-6 text-center">
                      <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoUno}></img>
                  </div>
                  <div className="col-md-6 text-center">
                      <img className="imagenPartido mb-5" alt="" style={{ display: "block", margin: "auto" }} src={partido[0].imagenEquipoDos}></img>
                  </div>
              </div>
          </div>
          <h2 className=' text-center'>{partido[0].nombreEquipoUno + " vs " + partido[0].nombreEquipoDos}</h2>
          <p  className=' text-center'>{partido[0].torneo}</p>
          <p  className=' text-center'>{partido[0].nombreEstadio}</p>
        </div>
          : null
        }
          


          
        {/* Videos */}
        <div className="container mt-5"> 
          <div id="contenedorVideos" className="row">
            {(videos && videos.length > 0) ? (
            videos.map((videoActual, index) =>
              videoActual.urlVideo !== "NA" ? (
              <div key={index} className="col-md-3 mb-5 d-flex flex-column align-items-center" >
                  
                <div className="contenedor-video-grabacion position-relative" onClick={(event) => {
                    const datosAdicionales = {
                    idVideo: videoActual.video.idVideo,
                    };
                    handleVideoClick(datosAdicionales);
                }}>
                    {/* Envolver el video en un Link para redirigir */}
                    <video
                    id={`video-${index}`}
                    className="video-js vjs-default-skin videoEnvVerVideos"
                    preload="auto"
                    poster={(videoActual.video.urlImagen) ? videoActual.video.urlImagen : ""}
                    data-setup="{ddd}"
                    style={{ width: '100%' }}
                    >
                    <source
                        src={`${videoActual.video.urlVideo}?v=${new Date().getTime()}`}
                        type="video/MP4"
                    />
                    </video>
                    
                    {/* Ícono de Play superpuesto */}
                    <div className="overlay-play-icon">
                        <i className="bi bi-play-circle iconoReproducir" ></i>
                    </div>
                </div>

                {/* Descripción del video */}
                <div className="d-flex justify-content-center align-items-center my-2 text-white">
                    <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{videoActual.video.equipo}</strong></p>
                    <p className="textosDescripcionUno text-center me-2 mb-0">{videoActual.video.tipo}</p>
                    
                    <div className="d-flex align-items-center me-2 text-white">
                        <i className="textosDescripcionUno bi bi-heart me-1"></i>
                        <p className="textosDescripcionUno text-center mb-0">{videoActual.video.meGusta.length}</p>
                    </div>
                    
                    <div className="d-flex align-items-center me-2 text-white">
                        <i className="textosDescripcionUno bi bi-play me-1"></i>
                        <p className="textosDescripcionUno text-center mb-0">{videoActual.video.vistas}</p>
                    </div>
                </div>
              </div>
              ) : null
            )
            ) : (
            <p className='text-white'>Buscando partido.</p>
            )}
          </div>

          {/* Botones paginación */}
          <div className="row mb-3">
              <div className="col-6"> <button className={   "w-100 my-3  btn btn-primary"  + (paginaAnterior < 1 ? " disabled" : "")    }  onClick={atras} disabled={paginaAnterior < 1} > Atrás  </button> </div>
              <div className="col-6"> <button className={   "w-100 my-3  btn btn-primary"  + (siguientePagina > cantidadDePaginas ? " disabled" : "")    }  onClick={adelante} disabled={siguientePagina > cantidadDePaginas} > Adelante  </button> </div>
          </div>
        </div>

      </div>
        

      

      
      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}
  </div>
  </div>
  )
}



export default VerPartidoIndividualUsuario;