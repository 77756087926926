import '../../estilos/paginas.css';


function RegistrarParaVer() {

    return (
        <div className="container my-5 text-light" >
        <div className="row">
          {/* Columna para el texto */}
          <div className="col-md-6 d-flex align-items-center justify-content-start">
            <h5 className="text-center mb-5">¿Quieres ver más? haz click y regístrate o inicia sesión</h5>
          </div>
          
          {/* Columna para los enlaces */}
          <div className="col-md-6 d-flex flex-column align-items-start">
            <a className="btn btn-primary w-100 mb-3 btn-registrar-borde p-2" href="/registrar" role="button">Registrar</a>
            <a className="btn btn-secondary w-100 p-2" href="/iniciar-sesion" role="button">Iniciar Sesión</a>
          </div>
        </div>
      </div>
      
    )
}



export default RegistrarParaVer;