import React, {useState, useEffect} from "react";
import SidebarUsuario from '../../partials/sidebarUsuario';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import CambiarFoto from "./cambiarFoto";
import CambiarContrasena from "./cambiarContrasena";
import ContenedorFiltros from "./contenedorFiltros";
import axios from "axios";
import CrearPartidoPago from "./crearPartidoPago";

function Pagos() {
    
    const location = useLocation();
    const navigate = useNavigate();
    const routeParams = useParams();

    let username = routeParams.username;
    let urlVolver = "/cliente/mi-cuenta/" + routeParams.username;
    const [infoUsuarioLocal, setInfoLocal]= useState(null);
    const [avatar, setAvatar] = useState("");
    const [pagos, setPagos] = useState(null);
    const [equipos, setEquipos] = useState(null);
    const [estadios, setEstadios]  = useState([]);
    
    
    const [precios, setPrecios ] = useState({
        canchaUno: 35000,
        canchaDos: 55000,
    })
    
    useEffect(() => {

        async function ObtenerPagosUsuario(){
            await axios.post("/obtener-pagos", {
                params: { username: username }
            })
            .then(async function(response){
                if(response.status===200){ 
                    setPagos(response.data.pagos);
                }
            });
        }

        ObtenerPagosUsuario();

        async function ObtenerEstadios(){
            await axios.post("/obtener-estadios-para-editar")
            .then(async function(response){
                setEstadios(response.data.estadios);
            });
        }
        ObtenerEstadios();
        

        async function ObtenerEquipos(){
            await axios.post("/obtener-equipo-para-editar")
            .then(async function(response){
                setEquipos(response.data.equipos);
            });
        }
        ObtenerEquipos();


        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        const usuarioEncontrado = JSON.parse(localStorage.getItem('infoUsuarioLocal')); // Leemos la información de autentiacción. Si existe, guardamos en el estado.
        if(usuarioEncontrado){setInfoLocal(usuarioEncontrado); setAvatar(usuarioEncontrado.usuario.avatar);} // aunque se actualiza infoUsuarioLocal, realmente usaremos para todo: usuarioEncontrado.
        else if(usuarioEncontrado===null){ navigate("/alerta", {state: {titulo:"No estás autenticado", mensaje:"No estás autenticado, por favor inicia sesión.", urlMensaje:"/iniciar-sesion", nombreBoton:"Volver a iniciar sesión"} } );}
        // AUTENTICACIÓN ///////////////////////////////////////////////////////////////////////
        // Modificar la clase de body par página de registro - styles.css /////////
        document.body.classList.add('claseBodyPrivadas');
    return () => { document.body.classList.remove('claseBodyPrivadas') }
    }, [location.state, navigate]);
    
    
    
    async function pagar(datos){
        
        try {
            
            await axios.post("/crear-preferencia", {
                headers: { 'Content-Type': 'multipart/form-data',},
                params: {  datos: datos, idUsuario: infoUsuarioLocal.usuario.idCLV, username: infoUsuarioLocal.usuario.username, email: infoUsuarioLocal.usuario.email }
            })
            .then(async function(response){
                console.log(response);
                window.location.href = response.data.url; // Redirigir a la URL de pago
            });
        } 
        catch (error) { console.error('Error al procesar el pago', error);  }

    }

   

    return(
        <div className=''>
            <div className='d-flex flex-row '>

                {/* BARRA LATERAL */}
                <SidebarUsuario />

 
                {/* Columna 2 */}
                <div className="flex-grow-1 container-fluid">
                    
                    {/* Contenedor título  */}
                    <div className="d-flex justify-content-between align-items-center pt-3 pb-2 mb-3 border-bottom border-white">
                        <h1 className="text-white h2">Pagos</h1>

                        {/* Contenedor para búsqueda y filtro */}
                        {/* <ContenedorFiltros urlVolver={urlVolver} usuario={username} /> */}

                        {/* Avatar y saludo */}
                        <div className="d-flex align-items-center text-white">
                            <h4 className="textoHomeUsuario mx-2">Hola {(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: null}</h4>
                            <img className="imgHomeUsuario" src={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.avatar: null} alt="" />
                        </div>
                    </div>


 
                    {/* Realizar un pago */}
                    <div className="p-5 mb-4 bg-body-tertiary rounded-3">
                       
                        <div className="row w-100">
                            {/* Columna 1 */}
                            <div className="col-md-6 d-flex flex-column justify-content-center">
                            <h2 className="display-5 fw-bold">¡Realiza un pago aquí!</h2>
                            <p className="col-md-12 fs-4">
                                Si deseas grabar un partido para que se publique en Cómo la vio, el primer paso es realizar la compra de un partido.
                            </p>
                            </div>
                            {/* Columna 2 */}
                            <div className="col-md-6 d-flex justify-content-center">
                            <img className='mpImagen' src='/imagenes/webGlobal/mp.jpg' alt="Imagen de pago" />
                            </div>
                        </div>
                       


                        {/* Contenedor de pago Uno */}
                        <div className="row gx-3 gy-2 align-items-center mb-1 py-3">

                            {/* Cantidad */}
                            <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="1"  />
                                    <label htmlFor="floatingInputGrid">Cantidad</label>
                                </div>
                            </div>

                             {/* Producto */}
                             <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="Fútbol 5, 6, 7"  />
                                    <label htmlFor="floatingInputGrid">Producto</label>
                                </div>
                            </div>


                            {/* Precio */}
                            <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={ "$ " + precios.canchaUno}  />
                                    <label htmlFor="floatingInputGrid">Precio</label>
                                </div>
                            </div>

                            
                            
                            {/* Botón cambio de imagen */}
                            <div className="col-sm-6">
                                <button
                                className="w-100 btn btn-primary btn-lg"
                                onClick={(event) => {
                                    const datosAdicionales = {
                                    cantidad: 1,
                                    valor: precios.canchaUno,
                                    producto: "futbol678"
                                    };
                                    pagar(datosAdicionales);
                                }}
                                >
                                Pagar Fútbol 5, 6, 7
                                </button>
                            </div>
                        </div>

                        {/* Contenedor de pago Dos */}
                        <div className="row gx-3 gy-2 align-items-center mb-1 py-3">

                            {/* Cantidad */}
                            <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="1"  />
                                    <label htmlFor="floatingInputGrid">Cantidad</label>
                                </div>
                            </div>

                             {/* Producto */}
                             <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue="Fútbol 11"  />
                                    <label htmlFor="floatingInputGrid">Producto</label>
                                </div>
                            </div>


                            {/* Precio */}
                            <div className="col-sm-2">
                                <div className="form-floating">
                                    <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={ "$ " + precios.canchaDos}  />
                                    <label htmlFor="floatingInputGrid">Precio</label>
                                </div>
                            </div>

                            
                            
                            {/* Botón cambio de imagen */}
                            <div className="col-sm-6">
                                <button
                                className="w-100 btn btn-primary btn-lg"
                                onClick={(event) => {
                                    const datosAdicionales = {
                                    cantidad: 1,
                                    valor: precios.canchaDos,
                                    producto: "futbol11"
                                    };
                                    pagar(datosAdicionales);
                                }}
                                >
                                Pagar Fútbol 11
                                </button>
                            </div>
                        </div>
                    </div>





                    

                    {/* Pagos anteriores */}
                    <div className="p-5 mb-4 bg-dark text-light rounded-3">
                        <div className="container-fluid py-5">
                            <h3 className="">Pagos realizados</h3>
                            <p className="">Aqui puedes ver los pagos que haz realizado anteriormente y crear los partidos para iniciar a grabar.</p>
                        </div>
                    
                        {/* Contenedor de pago */}
                        {(pagos && pagos.length > 0) ?
                            pagos.map( (actual, key) => (

                            <div className="row gx-3 gy-2 align-items-center mb-1 py-3" key={key} >
                                {/* Cantidad */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.idPago}  />
                                        <label htmlFor="floatingInputGrid">id Pago</label>
                                    </div>
                                </div>

                                {/* Producto */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.producto}  />
                                        <label htmlFor="floatingInputGrid">Producto</label>
                                    </div>
                                </div>

                                {/* Precio */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={"$ " +actual.monto}  />
                                        <label htmlFor="floatingInputGrid">Precio</label>
                                    </div>
                                </div>

                                {/* Moneda */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.moneda}  />
                                        <label htmlFor="floatingInputGrid">Moneda</label>
                                    </div>
                                </div>
                                
                                {/*Método de pago */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.metodo_pago}  />
                                        <label htmlFor="floatingInputGrid">Método</label>
                                    </div>
                                </div>


                                {/* Precio */}
                                <div className="col-sm-2">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={(actual.idPartido===-1) ? "Sin partido" : actual.idPartido}  />
                                        <label htmlFor="floatingInputGrid">Partido</label>
                                    </div>
                                </div>

                                {/* fecha */}
                                <div className="col-sm-4">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.creado_en}  />
                                        <label htmlFor="floatingInputGrid">Fecha</label>
                                    </div>
                                </div>

                                {/* estado del pago */}
                                <div className="col-sm-4">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.estado}  />
                                        <label htmlFor="floatingInputGrid">Estado</label>
                                    </div>
                                </div>

                                {/* detalle del estado */}
                                <div className="col-sm-4">
                                    <div className="form-floating">
                                        <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={actual.detalle_estado}  />
                                        <label htmlFor="floatingInputGrid">Detalle del estado</label>
                                    </div>
                                </div>


                                {/* boton MODAL */}
                                <div className="col-sm-12">
                                 <CrearPartidoPago pago={actual} username={(infoUsuarioLocal) ? infoUsuarioLocal.usuario.username: ""}  equipos={(equipos) ? equipos : []} estadios={(estadios) ? estadios : []} />
                                </div>
                            </div>
                        ))
                        : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Pagos;