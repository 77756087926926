import React from "react";

function ContenedorPartidos({ partidos }) {

  console.log(partidos);

  return (
    <div className="container contenedor-padre-01 contenedor-no-en-xs">
      {/* Primera fila */}
      <div className="row"><h2 className="titulo-partidos-01">ÚLTIMOS PARTIDOS</h2></div>

      {/* Segunda fila */}
      <div className="row mx-2">
        {partidos &&
          partidos.map((partido, index) => (
            <div key={index} className="partido-01 my-1">
              {/* equipo 1 */}
              <div className="equipo-01">
                <img
                  src={partido.equipoUno.imagen}
                  alt={partido.equipoUno.nombre}
                  className="imagen-equipo-01"
                />
                <p className="nombre-equipo-01">{partido.equipoUno.nombre}</p>
              </div>
              {/* vs */}
              <div className="text-center">
                {/* <p className="vs-texto-01">VS</p> */}
                <p className="textosBajovs">Deporte: {partido.partido.deporte}</p>
                <p className="textosBajovs">Estadio: {partido.estadio.nombre}</p>
                <p className="textosBajovs">Torneo: {partido.torneo.nombre}</p>
                <p className="textosBajovs">Fecha: { partido.partido.fechaHora.dia + " / " + partido.partido.fechaHora.mes + " / " + partido.partido.fechaHora.año}</p>
                <p className="textosBajovs">Hora: { partido.partido.fechaHora.hora + ":" + partido.partido.fechaHora.minuto }</p>
                <a
                  className="mt-1 btn btn-sm btn-outline-light"
                  href={
                    "/ver-partido/" +
                    partido.partido.idPartido +
                    "/" +
                    partido.equipoUno.nombre.toLowerCase().replace(/ /g, "-") +
                    "-vs-" +
                    partido.equipoDos.nombre.toLowerCase().replace(/ /g, "-")
                  }
                >
                  Ver partido
                </a>
              </div>
              {/* equipo 2 */}
              <div className="equipo-02">
                <p className="nombre-equipo-02">{partido.equipoDos.nombre}</p>
                <img
                  src={partido.equipoDos.imagen}
                  alt={partido.equipoDos.nombre}
                  className="imagen-equipo-02"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

 
export default ContenedorPartidos;
