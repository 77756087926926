import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';

function CambiarContrasena(props) {

    const navigate = useNavigate();
 
    const { urlVolver, usuario } = props; // contiene la url para volver: /cliente/mi-cuenta/eduardo2
    const [show, setShow] = useState(false); // mostrar modal
    const handleClose = () => setShow(false); // cerrar modal
    const handleShow = () => setShow(true); // mostrar modal

    

    const[inputDos, setInputDos] = useState ({
        usuario: usuario,
        contraActual: "",
        nuevaContra: ""
    });
    
    function handleChange2(event) {
        const { name, value } = event.target;

        // Expresión regular para permitir solo letras y números, sin espacios
        const alphanumericRegex = /^[a-zA-Z0-9]*$/;

        // Validar el valor antes de actualizar el estado
        if (alphanumericRegex.test(value)) {
            setInputDos(prevInputDos => {
                return {
                    ...prevInputDos,
                    [name]: value
                };
            });
        }
    }


    async function controlCambioContra(event){
        event.preventDefault();
        
        // Enviamos a subir la imagen
        await axios.post("/cambiar-contrasena", {
            params: {  usuario: inputDos.usuario, contraActual: inputDos.contraActual, nuevaContra: inputDos.nuevaContra }
        })
        .then(async function(response){
          
            if(response.data.status===200){
                return navigate("/alerta", {state: {titulo:"Contraseña modificada", mensaje:"Su contraseña ha sido cambiada. Por favor cierre y vuelva a iniciar sesión.", urlMensaje: urlVolver, nombreBoton:"Volver"} });
            }
            else{
                return navigate("/alerta", {state: {titulo:"Error", mensaje: response.data.mensaje, urlMensaje: urlVolver, nombreBoton:"Volver"} });
            }
            
        });

    }


    

    return(
        <div>
            <Button  variant="outline-primary" onClick={handleShow} className="text-white">
                Cambiar contraseña
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cambiar Contraseña</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>
                    <p>Ingresa tu contraseña actual y la nueva contraseña. Solo letras y números, sin espacios.</p>
                    <form>
                        {/* Username */}
                        <div className="form-floating my-3">
                            <input type="text" id="floatingInputGrid" readOnly className="form-control" defaultValue={usuario}  />
                            <label htmlFor="floatingInputGrid">Username</label>
                        </div>

                        {/* contraseña actual */}
                        <div className="form-floating my-3">
                            <input onChange={handleChange2} value={inputDos.contraActual} name="contraActual" type="password" className="form-control" id="floatingPassword2" placeholder="Password" />
                            <label htmlFor="floatingPassword2">contraseña actual</label>
                        </div>

                        {/* nueva contraseña */}
                        <div className="form-floating my-3">
                            <input onChange={handleChange2} value={inputDos.nuevaContra} name="nuevaContra" type="password" className="form-control" id="floatingPassword3" placeholder="Password" />
                            <label htmlFor="floatingPassword3">Nueva contraseña</label>
                        </div>  


                        <button type="submit" className="btn btn-primary w-100" onClick={controlCambioContra} >Cambiar contraseña</button>

                    </form>
                </Modal.Body>

                {/* Footer */}
                <Modal.Footer>
                    <Button className="w-100" variant="secondary" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default CambiarContrasena;