import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import 'swiper/swiper-bundle.css'; // Importa los estilos de Swiper
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import axios from "axios";
import RegistrarParaVer from './registrarParaVer';
import ContenedorTorneos from './contenedorTorneos';
import ContenedorPartidos from './contenedorPartidos';


function Home() {
  const navigate    = useNavigate();

  const [torneos, setTorneos] = useState(null);
  const [videos, setVideos]   = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);



  // Modificar la clase de body par página de registro - styles.css /////////
  useEffect(() => {

    async function inicializar() {
      try {
        setCargando(true);
        // Obtener torneos
        const responseTorneos = await axios.post("/obtener-torneos", {
          params: { deporte: "futbol" },
        });
        if (responseTorneos.status === 200) {
          setTorneos(responseTorneos.data.torneos);
        }
  
        // Obtener últimos videos
        const responseVideos = await axios.post("/obtener-ultimos-videos", {
          params: { deporte: "futbol" },
        });
        if (responseVideos.status === 200) {
          setVideos(responseVideos.data.videos);
        }
  
        // Obtener últimos partidos
        const responsePartidos = await axios.post("/obtener-ulitmos-partidos");
        if (responsePartidos.status === 200) {
          setPartidos(responsePartidos.data.partidos);
        }
      } 
      catch (error) { console.error("Error al inicializar:", error);}
      finally { setCargando(false); }
    }
  
    inicializar();
  }, []);
  // Modificar la clase de body par página de registro - styles.css /////////
  

  async function verVideo(video){
    
    const nombreEquipoUnoURL = video.nombreEquipoUno.toLowerCase().replace(/\s+/g, '-');
    const nombreEquipoDosURL = video.nombreEquipoDos.toLowerCase().replace(/\s+/g, '-');

    let nombrePartido = nombreEquipoUnoURL + "-vs-" + nombreEquipoDosURL;
    let urlAverVideo = "/video/" + video.video.idVideo; 

    // Navegar y forzar la recarga
    navigate(urlAverVideo, { replace: true });
    window.location.reload();  // Esto forzará la recarga completa de la página
  }

    
  return(
    <div className=''>
      {/* Primer contenedor: imagen principal */}
      <div className='imagen-container'>
        <img
          className='imagen-desktop'
          style={{ width: "100%"}}
          src='/imagenes/webGlobal/clvoficialtel.png'
          
          alt=''
        />
        <img
          className='imagen-mobile'
          style={{ width: "100%" }}
          src='/imagenes/webGlobal/clvoficialtel.png'
          alt=''
        />
        {/* <p className='subContenedorPrincipal'>Captura los mejores momentos</p> */}
      </div>



      {/* Segund contenedor: iconos y textos */}
      <div className='container my-4'>
        <div className='row'>

          {/* Icono y texto 1 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="bi bi-camera-video text-light"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4 className='text-light'>Capturamos el partido</h4>
                <p>Los mejores momentos disponibles</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 2 */}
          <div className='col-6 col-lg-3 contenedorTarjetas contenedorTarjetasUnoYdos'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="fa-solid fa-futbol text-light"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4 className='text-light'>Diferentes deportes</h4>
                <p>Encuentra tu deporte favorito</p>
              </div>
            </div>
          </div>


          {/* Icono y texto 3 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'><i className="bi bi-film text-light"></i></div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4 className='text-light'>Clips</h4>
                <p>Repite fragmentos del partido</p>
              </div>
            </div>
          </div>

          {/* Icono y texto 4 */}
          <div className='col-6 col-lg-3 contenedorTarjetas'>
            {/* fila contenedora */}
            <div className='d-flex flex-row align-items-center justify-content-start'>
              {/* Ícono */}
              <div className='p-2'>
              <i className="fa-brands fa-slideshare text-light"></i>
              </div>
              {/* Textos */}
              <div className='pt-2 px-2'>
                <h4 className='text-light'>Comparte</h4>
                <p>Envía clips a tus amigos!</p>
              </div>
            </div>
          </div>

        </div>
      </div>


      {/* Carrusel de videos */}
      {(videos)
      ? <div className='mx-1'>
        <Swiper
          spaceBetween={10} // Espacio entre los slides
          navigation // Habilita los botones de navegación
          pagination={{ clickable: true }} // Habilita la paginación
          autoplay={{
            delay: 3000, // Tiempo en ms entre transiciones
            disableOnInteraction: false, // No detener el autoplay después de la interacción
          }}
          modules={[Navigation, Pagination, Autoplay]} // Incluye los módulos
          breakpoints={{
            640: {
              slidesPerView: 1, // Muestra 1 video en pantallas pequeñas
            },
            768: {
              slidesPerView: 2, // Muestra 2 videos en pantallas medianas
            },
            1024: {
              slidesPerView: 4, // Muestra 4 videos en pantallas grandes
            },
        }}
        >
          {videos.map((video, index) => (
            <SwiperSlide key={index}>
              <div
                className="video-thumbnail"
                style={{
                  position: 'relative',
                  cursor: 'pointer',
                  textAlign: 'center', // Centra el texto debajo del video
                }}
                onClick={() => verVideo(video)} // Llama a la función al hacer clic
              >
                {/* Contenedor del video con overlay */}
                <div style={{ position: 'relative', borderRadius: '8px', overflow: 'hidden' }}>
                  <img
                    src={video.video.urlImagen || ""}
                    alt={`Video ${video.video.idVideo}`}
                    className="d-block w-100"
                    style={{ borderRadius: '8px' }} // Estilo opcional para el borde
                  />
                  {/* Overlay para el video */}
                  <div
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: 'rgba(0, 0, 0, 0.5)', // Fondo oscuro
                      color: 'white',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      opacity: 0.7,
                      borderRadius: '8px', // Para que coincida con la imagen
                    }}
                  >
                    <h5>Ver Video</h5>
                  </div>
                </div>


                {/* Descripción del video */}
                <div className="d-flex justify-content-center align-items-center my-2 text-light">
                  <p className="textosDescripcionUno text-center me-2 mb-0"><strong>{video.video.equipo}</strong></p>
                  <p className="textosDescripcionUno text-center me-2 mb-0">{video.video.tipo}</p>
                  {/* me gusta */}
                  <div className="d-flex align-items-center me-2 text-light">
                    <i className="textosDescripcionUno bi bi-heart  me-1"></i>
                    <p className="textosDescripcionUno text-center mb-0">{video.video.meGusta.length}</p>
                  </div>
              
                  {/* Vistas */}
                  <div className="d-flex align-items-center me-2 text-light">
                    <i className="textosDescripcionUno bi bi-play me-1"></i>
                    <p className="textosDescripcionUno text-center mb-0">{video.video.vistas}</p>
                  </div>
                </div>

                {/* Descripción 2*/}
                <div className='mt-3 text-light' >
                  <p className="textosDescripcionDos text-center me-2 mb-0">
                    {video.nombreEquipoUno + " vs " + video.nombreEquipoDos}
                  </p>
                  <p className="textosDescripcionDos text-center me-2 mb-0">{video.torneo}</p>
                  <p className="textosDescripcionDos text-center me-2 mb-0">{video.nombreEstadio}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      : null
      }

      {/* Contenedor torneos */}
      <ContenedorTorneos torneos={torneos} />

      <ContenedorPartidos partidos={partidos} />

      {/* Contendor video */}
      <div className='container mt-5'>
        <hr className="featurette-divider" />

        {/* video */}
        <div className='container text-center d-flex align-items-center justify-content-center '>
            <div className="container-fluid py-1 text-light">
                <h2 className="display-5 fw-bold">Cómo la vio</h2>
                <p className=" mb-5">Clips con los mejores momentos de tu partido favorito</p>
                
                <iframe className='claseVideoHome'
                  src="https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/videos/comolavio-1.mp4"
                  title="Cómo la vio" frameBorder="0"
                  allow="accelerometer; clipboard-write; encrypted-media;
                  gyroscope; picture-in-picture;
                  web-share"
                  allowFullScreen>
                </iframe>
            </div>
        </div>

        
      </div>

      <RegistrarParaVer />

      <div className='container'>
        <a href="https://wa.me/573132957130">
          <img  className="imagen-hover-123" style={{width: "100%"}} src='https://bucket-web-general-comolavio.s3.us-east-2.amazonaws.com/logos/Anuncios2.png'></img>
        </a>
      </div>


      {/* Tercer contendor: imágenes y textos informativos */}
      <div className="container marketing mb-5 text-light">

      <hr className="featurette-divider" />

        {/* Texto e imagen 1 */}
        <div className="row featurette primerContMark">
          {/* Texto */}
          <div className="col-md-7 mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5" style={{color: "rgb(101 129 123)"}}>Plataforma de clips. <span className="text-light">Una nueva forma de disfrutar los deportes.</span></h2>
            <p className="lead">Grabamos los momentos destacados de cada encuentro y te los mostramos en nuestra plataforma.</p>
            <p className="lead">Cada partido cuenta con jugadas que deseas volver a ver una y cientos de veces. Así como compartirlas con tus amigos, y nosotros lo hacemos posible.</p>
          </div>

          {/* Imagen */}
          <div className="col-md-5"><img  className='miImagenMark' src="/imagenes/webGlobal/plataforma.jpg" alt=''></img></div>
        </div>

        <hr className="featurette-divider"/>

        {/* Texto e imagen 2 */}
        <div className="row featurette">
          {/* Imagen */}
          <div className="col-sm-12 col-md-5 "><img  className='miImagenMark' src="/imagenes/webGlobal/clips.jpg" alt=''></img></div>
          {/* Texto */}
          <div className="col-sm-12 col-md-7  mt-5">
            <h2 className="featurette-heading fw-normal lh-1 mb-5"  style={{color: "rgb(101 129 123)"}}>Filtra tus clips favoritos. <span className="text-light">Por equipo, partido o deporte.</span></h2>
            <p className="lead">Sabemos que es clave poder encontrar rápidamente tus clips favoritos y por esto ponemos a tu disposición un amplio filtro par tu beneficio.</p>
            <p className="lead">Sabemos lo que te gusta y por esto en las preferencias de tu cuenta podrás determinar lo que más te gustaría ver dentro de todos los clips de la plataforma.</p>
          </div>
          
        </div>
      </div>



      {/* zona cargando */}
      {cargando && (
          <div className="overlay">
          <div className="loader"></div>
          <p className="loadingText">Buscando torneo, por favor espere...</p>
          </div>
      )}

    </div>
  )
}



export default Home;