import '../../estilos/paginas.css';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from "react-router-dom";
import axios from "axios";
import RegistrarParaVer from './registrarParaVer';
 

function VerFecha() {

  const navigate    = useNavigate();
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let { nombreTorneo, fecha }   = routeParams; // capturamos para paginación

  const [torneo, setTorneo] = useState(null);
  const [partidos, setPartidos] = useState(null);
  const [cargando, setCargando]  = useState(true);
  const [patrocinador, setPatrocinador] = useState(null);
  
  

  useEffect(() => {

    async function obtenerDeportes(){
      await axios.post("/obtener-torneo-global", {
        params: {nombreTorneo: nombreTorneo, fecha: fecha}
      })
      .then(async function(response){
        if(response.status===200){ 
          setCargando(false); // quitamos la espera
          setTorneo(response.data.torneo); setPartidos(response.data.partidosEditados);
          setPatrocinador(response.data.patrocinador);
        }
        else{
          setCargando(false); // quitamos la espera
        }
      });
    }
    obtenerDeportes();





    // Modificar la clase de body par página de registro - styles.css /////////
    document.body.classList.add('claseBodyWeb')
    return () => {
      document.body.classList.remove('claseBodyWeb')
    }
  }, []);
  
 
    
  
 
  
  return(
    <div className=''>
      

      <div className="d-flex mx-3 flex-column flex-sm-row">

        {/* Columna 1: Banner patrocinador (Igual a la columna 3) */}
        {/* <div className="col-12 col-sm-3 sticky-container-01 px-5">
          <div className="sticky-content-01">
          {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
          <div>
              <h3 className="text-center mb-3 text-light">Patrocinado por</h3>
              <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer" className="link-container-01">
              <img 
                            className="img-fluid-01 mostrar-en-pequenas mb-5" 
                            src={patrocinador.logo} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.logo + Date.now()} 
                        />
                        <img 
                            className="img-fluid-full-01 ocultar-en-pequenas mb-5" 
                            src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                            alt="Banner Patrocinador" 
                            key={patrocinador.banners[0].urlBanner + Date.now()} 
                        />
              </a>
          </div>
          ) : (
          <img className="img-fluid-01 mb-3" src="/imagenes/marca/clvhorizontalblanco.png" alt="Banner Predeterminado" />
          )}
          </div>
        </div> */}

        {/* Columna 2: Video */}
        <div className="col-12 col-sm-9 container-fluid">
        {/* Video Contenedor */}
        {(torneo)
        ? <div>

          {/* Primer contenedor: imagen principal */}
          <div className='container'>
            <div className="p-5 my-4 bg-body-tertiary rounded-3">
              <div className="row  d-flex align-items-center" style={{ minHeight: "400px" }}>
                {/* Imagen */}
                <div className="col-lg-6 d-flex justify-content-center">
                  <div className="d-flex justify-content-center"> <img className="imagenPrincipal" src={(torneo) ? torneo.imagen : ""}  alt="" /></div>
                </div>

                {/* Nombre y texto */}
                <div className="col-lg-6 d-flex justify-content-center">
                  <div className="container-fluid py-5">
                    <h1 className="display-5 fw-bold">Torneo {(torneo) ? torneo.nombre : ""}</h1>
                    <h2 className="display-5 fw-bold">Fecha {fecha}</h2>
                    <p className="col-md-8 fs-4">Deporte: {(torneo) ? torneo.deporte : ""}</p>
                      
                    {(torneo.video && torneo.video!=="")
                    ? <video
                      className="video-js vjs-default-skin videoIndividual"
                      preload="auto"
                      controls
                      data-setup="{}"
                      style={{ height: "200px"}}
                      onError={(e) => console.error("Error al cargar el video:", e)}
                      >
                        <source src={torneo.video} type="video/mp4" />
                    </video>
                    : null
                    }
                  </div>
                </div>

                <p className="">{torneo.descripcion}</p>
              </div>
            </div>
          </div>


          
          <div className='container' >
            <a class="btn btn-warning w-100" href={"/ver-torneo/" + nombreTorneo} role="button">Volver</a>

            {/* Todos los videos */}
            <hr className="featurette-divider" />
            <h3 className='text-center mb-5 text-white'>Todos los videos del torneo.</h3>
            <div className="row">
            {(partidos) 
            ?  partidos.map((partidoActual, index) => {
            
              const nombreEquipoUnoURL = partidoActual.nombreEquipoUno.toLowerCase().replace(/\s+/g, '-');
              const nombreEquipoDosURL = partidoActual.nombreEquipoDos.toLowerCase().replace(/\s+/g, '-');

              return (
                <div className="col-sm-4 col-lg-4" key={index}>
                  <div className="card m-1">
                    <div  className="card-header text-light" style={{ backgroundColor: "#0E1465" }} >
                      id: {partidoActual._doc.idPartido}
                    </div>
                    <div className="d-flex justify-content-between align-items-center p-2">
                      <img  src={partidoActual.imagenEquipoUno}  alt=""  className="card-img-top imagenEquipos" />
                        <span className="">vs</span>
                      <img src={partidoActual.imagenEquipoDos} alt=""  className="card-img-top imagenEquipos" />
                    </div>
                    <div className="card-body">
                      <h5 className="card-title">{partidoActual.nombreEquipoUno} vs {partidoActual.nombreEquipoDos} </h5>
                      <p className="card-text"> <strong>Deporte:</strong> {partidoActual._doc.deporte} </p>
                      <a className="btn btn-primary w-100" href={"/ver-partido/" + partidoActual._doc.idPartido + "/" + nombreEquipoUnoURL + "-vs-" + nombreEquipoDosURL} role="button" >
                        Ver videos de Partido
                      </a>
                    </div>
                  </div>
                </div>
              )
            })
            : null}
            </div>

          </div>

          
          <RegistrarParaVer />
        </div>
        :null
        }
        </div>

        {/* Columna 3: Banner patrocinador (Igual a la columna 1) */}
        <div className="col-12 col-sm-3 sticky-container-01 px-5">
        <div className="sticky-content-01">
        {patrocinador && patrocinador.banners[0].urlBanner !== "" ? (
        <div>
        <h3 className="text-center mb-3 text-light">Patrocinado por</h3>
        <a href={patrocinador.banners[0].urlRedireccion} target="_blank" rel="noopener noreferrer" className="link-container-01">
        <img 
                    className="img-fluid-01 ocultar-en-pequenas mb-5" 
                    src={patrocinador.logo} 
                    alt="Banner Patrocinador" 
                    key={patrocinador.logo + Date.now()} 
                />
                <img 
                    className="img-fluid-full-01 mostrar-en-pequenas mb-5" 
                    src={`${patrocinador.banners[0].urlBanner}?t=${Date.now()}`} 
                    alt="Banner Patrocinador" 
                    key={patrocinador.banners[0].urlBanner + Date.now()} 
                />
        </a>
        </div>
        ) : (
        <img className="img-fluid-01 mb-3" src="/imagenes/marca/clvhorizontalblanco.png" alt="Banner Predeterminado" />
        )}
        </div>
        </div>

      </div>



      {/* zona cargando */}
      {cargando && (
      <div className="overlay">
      <div className="loader"></div>
      <p className="loadingText">Buscando torneo, por favor espere...</p>
      </div>
      )}
      
    </div>
  )
}



export default VerFecha;