function FooterGlobal(){
    return(
        <div className="contenedorFooterGlobal">
            <div className="container">
                <footer className="pt-5">
                    {/* Primera parte */}
                    <div className="row">





                        {/* Consumidor */}
                        <div className="col-12 col-md-3 mb-3">
                           <div>
                                {/* Imagen del logo */}
                                <img 
                                    src="/imagenes/marca/clvhorizontalblanco.png" 
                                    alt="Logo" 
                                    className="logo-navbar small-logo mb-3"
                                />
                                {/* Descripción */}
                                <p style={{ textAlign: 'justify' }}>Graba y muestra tu partido en Cómo la vio. Grabamos los momentos destacados de cada encuentro y te los mostramos en nuestra plataforma.</p>
                            </div>
                        </div>





                        {/* Cómo la vio */}
                        <div className="col-12 col-md-2 mb-3">
                            <h5 className="text-light">Empresa</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2">
                                    <a href="/faq" className="nav-link p-0 text-body-secondary d-flex align-items-center">
                                        <i className="bi bi-check2-circle me-2"></i>
                                        FAQ
                                    </a>
                                </li>
                                <li className="nav-item mb-2">
                                    <a href="/contacto" className="nav-link p-0 text-body-secondary d-flex align-items-center">
                                        <i className="bi bi-check2-circle me-2"></i>
                                        Contacto
                                    </a>
                                </li>
                                <li className="nav-item mb-2">
                                    <a href="/paga-y-graba" className="nav-link p-0 text-body-secondary d-flex align-items-center">
                                        <i className="bi bi-check2-circle me-2"></i>
                                        Grabar
                                    </a>
                                </li>
                                <li className="nav-item mb-2">
                                    <a href="/ver-videos/futbol" className="nav-link p-0 text-body-secondary d-flex align-items-center">
                                        <i className="bi bi-check2-circle me-2"></i>
                                        Torneos
                                    </a>
                                </li>
                                <li className="nav-item mb-2">
                                    <a href="/videos" className="nav-link p-0 text-body-secondary d-flex align-items-center">
                                        <i className="bi bi-check2-circle me-2"></i>
                                        Ver videos
                                    </a>
                                </li>
                            </ul>
                        </div>

                        {/* Usuario */}
                        <div className="col-12 col-md-2 mb-3">
                            <h5 className="text-light">Usuario</h5>
                            <ul className="nav flex-column">
                                <li className="nav-item mb-2"><a href="/iniciar-sesion" className="nav-link p-0 text-body-secondary">Iniciar Sesión</a></li>
                                <li className="nav-item mb-2"><a href="/registrar" className="nav-link p-0 text-body-secondary">Registrar</a></li>
                            </ul>
                        </div>      
                        {/* Contáctanos */}
                        <div className="col-md-3 offset-md-1 mb-3">
                            <h5 className="text-light">Contáctanos</h5>
                            
                            {/* Email */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-envelope-at-fill"></i></div>
                                <div className="pe-2"><p>contacto@comolavio.com</p></div>
                            </div>
                            {/* Teléfono */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-telephone-outbound-fill"></i></div>
                                <div className="pe-2"><p>57 313 295 7130</p></div>
                            </div>
                            {/* Dirección */}
                            <div className="d-flex flex-row">
                                <div className="pe-2"><i className="bi bi-geo-alt-fill"></i></div>
                                <div className="pe-2"><p>Cll. 20 #82 - 52, Bogotá</p></div>
                            </div>

                            <div className="d-flex ">
                                <a className="link-body-emphasis pe-2" href="https://www.facebook.com/profile.php?id=61566444787956" target="_blank" rel="noreferrer"><i className="bi bi-facebook"></i></a>
                                <a className="link-body-emphasis px-2" href="https://wa.me/573132957130 " target="_blank"  rel="noreferrer"><i className="bi bi-whatsapp"></i></a>
                                <a className="link-body-emphasis px-2" href="https://www.instagram.com/como_la_vio " target="_blank"  rel="noreferrer"><i className="bi bi-instagram"></i></a>
                                <a className="link-body-emphasis px-2" href="https://www.tiktok.com/@comolavio" target="_blank" rel="noreferrer"><i className="bi bi-tiktok"></i></a>
                                <a className="link-body-emphasis px-2" href="https://youtube.com/@oficialcomolavio" target="_blank"  rel="noreferrer"><i className="bi bi-youtube"></i></a>
                                
                            </div>








                        </div>

                    </div>






                    {/* Segunda parte  */}
                    <div className="row border-top pt-4 mt-4 pb-3">
                        {/* Enlaces de política */}
                        <div className="col-12 col-md-6">
                            <ul className="nav flex-row justify-content-start mb-0">
                            <li className="nav-item-01 mb-2">
                                <a href="/privacidad" className="nav-link-01 p-0" style={{ textDecoration: "none" }}>Política de privacidad</a>
                            </li>
                            <li className="nav-item-01 mb-2">
                                <a href="/terminos" className="nav-link-02 p-0" style={{ textDecoration: "none" }}>Términos y condiciones</a>
                            </li>
                            <li className="nav-item-01 mb-2">
                                <a href="/cookies" className="nav-link-03 p-0" style={{ textDecoration: "none" }}>Política de cookies</a>
                            </li>
                            </ul>
                        </div>

                        {/* Copyright y mensaje del equipo */}
                        <div className="col-12 col-md-3 text-center text-md-start">
                            <p>Copyright © 2024, Cómo la vio.</p>
                        </div>

                        {/* Hecho por Pretwor */}
                        <div className="col-12 col-md-3 text-center text-md-end">
                            <p>Hecho por <a style={{ textDecoration: "none" }} href="https://pretwor.com/" target="_blank" rel="noreferrer">Pretwor</a></p>
                        </div>
                    </div>

                </footer>
            </div>
        </div>
    )
}

export default FooterGlobal;