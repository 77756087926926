import '../estilos/partials.css';
import {useLocation,  useParams, useNavigate, Link} from 'react-router-dom';
import React, {useState, useEffect} from "react";
import axios from "axios";
import ContenedorFiltrosGlobal from '../paginas/webGlobal/contenedorFiltrosGlobal.jsx';


function HeaderGlobal(){

  const navigate    = useNavigate();
  
  const location    = useLocation(); // cuando no se envía nada en state, location,state es null
  const routeParams = useParams();
  let {username, pagina, tipoBusqueda, cualFiltro, contenido} = routeParams; // capturamos para paginación

  const [infoUsuarioLocal, setInfoLocal]= useState(null); // Variables localstorage
  const [avatar, setAvatar] = useState("");
  const [videosMostrar, setVideosMostrar] = useState(null);
  const [cargando, setCargando] = useState(true);
  const [siguientePagina, setSiguientePagina] = useState(null);
  const [paginaAnterior, setPaginaAnterior] = useState(null); 
  const [cantidadDePaginas, setCantidadDePaginas] = useState(null);
  const [equipos, setEquipos]     = useState([]);
  const [estadios, setEstadios]   = useState([]);
  const [torneos, setTorneos]     = useState([]);
  const [busquedaMostrada, setBusquedaMostrada] = useState(null);


  const [paginaActual, setPaginaActual] = useState(() => {
      return parseInt(localStorage.getItem("paginaActual")) || 1;
  });
  const [tipoBusquedaNuevo, setTipoBusquedaNuevo] = useState(() => {
    return localStorage.getItem("tipoBusquedaNuevo") || "NA";
  });
  const [cualFiltroNuevo, setCualFiltroNuevo] = useState(() => {
    return localStorage.getItem("cualFiltroNuevo") || "NA";
  });
  const [contenidoNuevo, setContenidoNuevo] = useState(() => {
    return localStorage.getItem("contenidoNuevo") || "NA";
  });








  useEffect(() => {
      
      async function obtenerDatos() {

          // Array de funciones a ejecutar
          const funciones = [

              async function obtenerEquipos() {
                  const response = await axios.post("/obtener-equipo-para-editar");
                  setEquipos(response.data.equipos);
              },

              async function obtenerEstadios() {
                  const response = await axios.post("/obtener-estadios-para-editar");
                  setEstadios(response.data.estadios);
              },

              async function obtenerTorneos() {
                  const response = await axios.post("/obtener-torneos", {
                      params: { deporte: "futbol" }
                  });
                  if (response.status === 200) {
                      setTorneos(response.data.torneos);
                  }
              },

              
          ];

          // Ejecuta todas las funciones en paralelo y espera a que todas terminen
          try {

              await Promise.all(funciones.map(funcion => funcion()));
          } catch (error) {
              console.error('Error al obtener datos:', error);
          } finally {
              //setCargando(false); // Cambia a false solo cuando todas las funciones terminan
          }
      }

      obtenerDatos(); // Ejecuta la función al montar el component








      const header = document.querySelector('.sticky-header');
      const modal = document.getElementById('modalBusqueda');

      // Al abrir el modal, desactivar el 'position: sticky'
      const onModalOpen = () => {
        header.style.position = 'static';
      };

      // Al cerrar el modal, restaurar el 'position: sticky'
      const onModalClose = () => {
        header.style.position = 'sticky';
      };

      // Agregar los event listeners para el modal de Bootstrap
      modal.addEventListener('show.bs.modal', onModalOpen);
      modal.addEventListener('hide.bs.modal', onModalClose);


      const offcanvasElement = document.getElementById('offcanvasMenu');
      const offcanvasBody = offcanvasElement.querySelector('.offcanvas-body ul');

      // Evento de Bootstrap para cuando el menú se abre
      offcanvasElement.addEventListener('show.bs.offcanvas', () => {
        offcanvasBody.style.opacity = 1;
        offcanvasBody.style.visibility = 'visible';
      });

      // Evento para cuando el menú se cierra
      offcanvasElement.addEventListener('hide.bs.offcanvas', () => {
        offcanvasBody.style.opacity = 0;
        offcanvasBody.style.visibility = 'hidden';
      });
      return ()=> {
          localStorage.setItem("paginaActual",1);
          modal.removeEventListener('show.bs.modal', onModalOpen);
          modal.removeEventListener('hide.bs.modal', onModalClose);
          
      }
  }, []);







  let urlVolver = "/";

  

  async function iniciarsesion(){
  
      var urlAver = "/iniciar-sesion";
      navigate(urlAver);
      window.location.reload();
  }
  async function registrar(){
  
      var urlAver = "/registrar";
      navigate(urlAver);
      window.location.reload();
  }
  
  async function ejectuarBusqueda(datos){
      
      let tipoDeBusqueda   = datos.tipo;      // búsqueda o filtro
      let busquedaForm = datos.busqueda       // Lo que el usuario escribe en el input
      .toLowerCase()                          // Convertir a minúsculas
      .trim()                                 // Eliminar espacios al inicio y al final
      .replace(/\s+/g, '-');                  // Reemplazar espacios intermedios por guiones
      let cualFiltroForm   = datos.cualFiltro;      // cual filtor selecciona el usuario
      let contenidoFiltroF = datos.contenidoFiltro; // contenido del filtro seleccionado
      


      // pagina: paginaActual, tipoBusqueda: tipoBusquedaNuevo, cualFiltro: cualFiltroNuevo, contenido: contenidoNuevo
      if(tipoDeBusqueda==="busqueda"){
          // actualizar variables de localstorge
          localStorage.setItem("tipoBusquedaNuevo", tipoDeBusqueda); // busquieda 
          localStorage.setItem("contenidoNuevo", busquedaForm); // lo que el usuario escribe 

          let urlAver = "/videos";
          navigate(urlAver);
          window.location.reload();
      }
      else if(tipoDeBusqueda==="filtro"){

          //actualizar variables de localstorage
          localStorage.setItem("tipoBusquedaNuevo",tipoDeBusqueda); // actualizamos a filtro
          localStorage.setItem("contenidoNuevo", contenidoFiltroF); // actualizamos el contenido 
          localStorage.setItem("cualFiltroNuevo", cualFiltroForm);

          let urlAver = "/videos";
          navigate(urlAver);
          window.location.reload();
      }
  }
  
  // contenedor filtro: 
  const [filtrosVisibles, setFiltrosVisibles] = useState(false);

  const toggleFiltros = () => {
    setFiltrosVisibles(!filtrosVisibles);
  };
    


  return(
  <div className='sticky-header'>
    <header className="navbar navbar-expand-sm navbar-dark py-3 px-5 container " aria-label="Third navbar example">
      <div className="container-fluid d-flex flex-wrap justify-content-center">
        {/* Contenedor para imagen, filtro y menú en pantallas pequeñas */}
        <ul className="barra mb-4 d-flex align-items-center mx-2 w-100 justify-content-between d-lg-none pantallasp">
          <li className="d-flex align-items-center justify-content-center">
            <a className="navbar-brand d-flex  link-body-emphasis text-decoration-none" href="/">
              <img src="/imagenes/marca/clvhorizontalblanco.png" alt="Logo" className="logo-navbar small-logo" />
            </a>
          </li>

          {/* Ícono de lupa que abre el modal */}
          <li className="nav-item d-flex align-items-center">
            <i
              className="bi bi-search nav-icon text-white"
              data-bs-toggle="modal"
              data-bs-target="#modalBusqueda"
              title="Abrir búsqueda"
            ></i>
          </li>

          {/* Botón de menú Offcanvas */}
          <button
            className="btn btn-menu-offcanvas d-lg-none" // Solo en pantallas pequeñas
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasMenu"
            title="Abrir menú lateral"
          >
            <i className="bi bi-list text-white"></i>
          </button>





          {/* Menú Offcanvas */}
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasMenu" aria-labelledby="offcanvasMenuLabel" style={{ backgroundColor: 'black'}}>
<div className="offcanvas-header" >
  {/* Imagen alineada a la derecha */}
  <div className="offcanvas-logo-container w-100 d-flex " style={{marginLeft: "0px" }}>
    <img
      src="/imagenes/marca/clvhorizontalblanco.png"
      alt="Logo"
      className="logo-navbar small-logo"
      style={{ width: '180px' }} // Puedes ajustar el tamaño según lo que necesites
    />
  </div>
  <button
    type="button"
    className="btn-close text-reset border border-white rounded-3"
    data-bs-dismiss="offcanvas"
    aria-label="Cerrar"
    style={{
      backgroundColor: 'white', 
      borderColor: 'white', 
      position: 'absolute', 
      top: '20px', 
      right: '20px', // Puedes ajustar estos valores según sea necesario
    }}
  ></button>
</div>
<div className="offcanvas-body" style={{ backgroundColor: 'var(--e-global-color-primary)' }}>
  {/* Menú */}
  <ul className="list-unstyled" style={{ opacity: 0, transition: 'opacity 0.5s ease', visibility: 'hidden' }}>
    <li><a className="nav-link text-white text-small" href="/videos">VER VIDEOS</a></li>
    <li><a className="nav-link text-white text-small" href="/ver-videos/futbol">TORNEOS</a></li>
    <li><a className="nav-link text-white text-small" href="/paga-y-graba">GRABAR</a></li>
  </ul>
</div>
</div>








          
        </ul>

        {/* Contenedor de los enlaces y botones en pantallas grandes */}
        <ul className="barra mb-2 mb-sm-0 d-flex align-items-center mx-5 d-none d-lg-flex">
          <li className="d-flex align-items-center">
            <a className="navbar-brand d-flex align-items-center mb-3 mb-lg-0 me-lg-auto link-body-emphasis text-decoration-none" href="/">
              <img src="/imagenes/marca/clvhorizontalblanco.png" alt="Logo" className="logo-navbar" />
            </a>
          </li>
          <li className="nav-item dropdown d-none d-lg-flex">
            <a className="nav-link nav-torneos fw-light text-small" href="/videos">VER VIDEOS</a>
          </li>
          <li className="nav-item d-none d-lg-flex">
            <a className="nav-link nav-torneos text-small" href="/ver-videos/futbol">TORNEOS</a>
          </li>
          <li className="nav-item d-none d-lg-flex">
            <a className="nav-link nav-torneos fw-light text-small" href="/paga-y-graba">GRABAR</a>
          </li>
          <li className="nav-item d-flex align-items-center d-none d-lg-flex mx-1">
            <i className="bi bi-search nav-torneos text-small" data-bs-toggle="modal" data-bs-target="#modalBusqueda" title="Abrir búsqueda"></i>
            <span className="text-white filtrar-texto fw-light ms-2 text-small" title="Buscar">BUSCAR</span>
          </li>
        </ul>

        {/* Segunda parte del menú con los botones (Iniciar sesión, Registrar) */}
        <div id="contenedorSegundaParteHeader" className="col-md-4 text-md-end text-center headerFinal">
          <button className="btn btn-iniciar-sesion me-3 w-100" type="button" onClick={iniciarsesion}>Iniciar Sesión</button>
          <button className="btn btn-registrar w-100" type="button" onClick={registrar}>Registrar</button>
        </div>
      </div>
    </header>

    {/* Modal de búsqueda */}
    <div className="modal fade modal-123" id="modalBusqueda" tabIndex="-1" aria-labelledby="modalBusquedaLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content-123 w-100">
          {/* <div className="modal-header w-100">
            <h5 className="modal-title" id="modalBusquedaLabel">Búsqueda</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Cerrar"></button>
          </div> */}
          <div className="modal-body w-100">
            <ContenedorFiltrosGlobal 
              urlVolver={urlVolver}
              usuario={username}
              equipos={equipos}
              estadios={estadios}
              torneos={torneos}
              cualBusqueda={ejectuarBusqueda}
            />
          </div>
        </div>
      </div>
    </div>
  </div>    
  )
}

export default HeaderGlobal;