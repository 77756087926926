import React from 'react';

const ContenedorTorneos = ({ torneos }) => {
  return (
    <div className="container contenedor-torneos-global my-5 contenedor-no-en-xs">
      <div className="contenedor-ajustado">
        <h2 className="titulo-torneos">Todos los torneos</h2>
        <div className="row">
          {torneos ? (
            torneos.map((actual, index) => {
              const nombreURL = actual.nombre.replace(/\s+/g, '-').toLowerCase();

              return (
                <div className="col-12 col-md-6" key={index}>
                  <div className="card m-2 torneo-card">
                    <div className="row align-items-center">
                      <div className="col-auto">
                        <img
                          src={actual.imagen}
                          alt="Imagen del torneo"
                          className="torneo-imagen"
                        />
                      </div>
                      <div className="col">
                        <h5 className="torneo-nombre">{actual.nombre}</h5>
                        <p className="torneo-descripcion">Deporte: {actual.deporte}</p>
                        <p className="torneo-descripcion">Fechas: {actual.cantidadDeFechas}</p>
                        <a
                          className="btn btn-primary w-100 mt-2"
                          href={`/ver-torneo/${nombreURL}`}
                          role="button"
                        >
                          Ver torneo
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <p>No hay torneos disponibles.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ContenedorTorneos;
